import React from 'react'
import { Link} from 'gatsby'


import Layout from '../components/layout'
import Head from '../components/head'

import textStyles from '../components/text.module.scss'
import linkStyles from '../components/link.module.scss'
import indexboxStyles from '../components/indexbox.module.scss'

const IndexPage = () => {
    return (
        <Layout>
            <Head title=""/>
            <div className={indexboxStyles.divbox}>
            <p className={textStyles.text}>  
                 <Link to="./work/festivalofdangerousideasidentity" className={linkStyles.link}>Festival of Dangerous Ideas</Link>
            <br/><Link to="./work/helveticabooklet" className={linkStyles.link}>Helvetica Booklet</Link>
            <br/><Link to="./work/lincolnnationalparkbranding" className={linkStyles.link}>Lincoln National Park</Link>
            <br/><Link to="./work/thehobbitbookdesign" className={linkStyles.link}>The Hobbit</Link>
            <br/><Link to="./work/abstractphotography" className={linkStyles.link}>Abstract Photography</Link>
            <br/><Link to="./work/surveillanceposter" className={linkStyles.link}>Surveillance Poster</Link>
            <br/><Link to="./work/styxcalligraphy" className={linkStyles.link}>Styx Calligraphy</Link>
            <br/><Link to="./work/limitlessposter" className={linkStyles.link}>Limit-Less Poster</Link>
            <br/><Link to="./work/conspiracyposter" className={linkStyles.link}>Conspiracy Poster</Link>
            </p>
            </div>
        </Layout>
    )
}

export default IndexPage